@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: AtypDisplay-Semibold;
    src: url('/fonts/AtypDisplay-Semibold.ttf');
  }

  @font-face {
    font-family: AtypDisplay-Medium;
    src: url('/fonts/AtypDisplay-Regular.ttf');
  }

  @font-face {
    font-family: AtypDisplay-Light;
    src: url('/fonts/AtypDisplay-Light.ttf');
  }

  :root {
    color-scheme: dark;
  }

  * {
    -webkit-print-color-adjust: exact !important;
    /* Chrome, Safari, Edge */
    print-color-adjust: exact !important;
    /*Firefox*/
  }

  @page {
    size: auto A4 portrait;
    /* auto is the initial value */
    overflow: hidden;
    margin: 1mm;
    /* this affects the margin in the printer settings */
  }

  html,
  body {
    @apply h-full;
  }

  body {
    @apply bg-royalty-blue;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .content p {
    @apply my-4 leading-loose;
  }

  .multiline-ellipsis {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 17;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  html {
    scroll-behavior: smooth;
  }
}
